const { VITE_ENVIRONMENT, VITE_VERSION } = import.meta.env

/**
 * Returns a timestamp string in a "HH:MM:SS" format.
 */
function getTimestamp(): string {
  const now = new Date()

  return [now.getHours(), now.getMinutes(), now.getSeconds()]
    .map(String)
    .map((chunk) => chunk.slice(0, 2))
    .map((chunk) => chunk.padStart(2, '0'))
    .join(':')
}

const sharedDatadogConfig = {
  clientToken: 'pubd6e945d1071ed0afa4ecd31d7739a98c',
  site: 'datadoghq.com',
  service: 'clinician',
  env: VITE_ENVIRONMENT,
  version: VITE_VERSION,
  sessionSampleRate: 100,
}

type StatusCode = 'error' | 'alert' | 'debug' | 'info' | 'warn' | 'ok' | 'notice' | 'critical' | 'emerg'

enum StatusCodeColor {
  Debug = 'inherit', // Use the default color for Debug
  Error = '#E95F5D',
  Info = '#89cff0',
  Warning = '#F0BB4B',
}

/**
 * Returns a HEX color for a given response status code number.
 */
function getStatusCodeColor(status: StatusCode): StatusCodeColor {
  if (status === 'debug') {
    return StatusCodeColor.Debug
  }

  if (['error', 'alert', 'critical', 'emerg'].includes(status)) {
    return StatusCodeColor.Error
  }

  if (['info', 'ok', 'notice'].includes(status)) {
    return StatusCodeColor.Info
  }

  return StatusCodeColor.Warning
}

export const initInstrumentation = async () => {
  if (VITE_ENVIRONMENT === 'production') {
    const { datadogRum } = await import('@datadog/browser-rum')

    datadogRum.init({
      ...sharedDatadogConfig,
      applicationId: '1aeb43db-0312-43d2-ba47-a01e9dc31ee9',
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackLongTasks: true,
      trackResources: true,
      workerUrl: new URL('@datadog/browser-worker', import.meta.url).href,
      defaultPrivacyLevel: 'mask-user-input',
      compressIntakeRequests: true,
    })
  }

  const { datadogLogs } = await import('@datadog/browser-logs')

  datadogLogs.init({
    ...sharedDatadogConfig,
    forwardErrorsToLogs: true,
    beforeSend: (log) => {
      // Don't log errors that are related to the browser not being able to fetch the page
      if (['Fetch error POST', 'XHR error POST'].some((ignoredLog) => log.message.includes(ignoredLog))) {
        return false
      }

      // Filter out crawler errors (i.e Outlook Email crawler), see https://stackoverflow.com/questions/75536442/troubleshooting-non-error-promise-rejection-captured-with-value-object-not-fou
      if (log.message.includes('Object Not Found Matching Id:')) {
        return false
      }

      // In non-production environments, log to the console instead of Datadog
      if (VITE_ENVIRONMENT !== 'production') {
        console.groupCollapsed(
          `[DATADOG] ${getTimestamp()} \`${log.message}\` (%c${log.status.toUpperCase()}%c)`,
          `color:${getStatusCodeColor(log.status)};`,
          'color:inherit;',
        )
        console.log('Payload', log)
        console.groupEnd()

        return false
      }

      return true
    },
  })

  datadogLogs.onReady(() => {
    // In non-production environments, log to the console instead of Datadog
    if (VITE_ENVIRONMENT !== 'production') {
      console.groupCollapsed(
        '%c[DATADOG] Logs being intercepted for non-production environments',
        'color:yellow;font-weight:bold;',
      )
      console.log('Change in the `src/core-web/instrumentation.ts` file if needed')
      console.groupEnd()
    }
  })
}
